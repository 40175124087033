<template>
   <div class="row m-0 bg-white text-dark rounded">
      <div class="col-12 p-3" style="width: 350px; font-size: 12px;">
         <div class="row m-0 align-items-center text-center">
            <div class="col-12 px-1" style="font-size: 14px;">{{ String(comprovante.lojaNome).toUpperCase() }}</div>
            <div class="col-12 px-1">
               CNPJ: {{ comprovante.lojaCnpj == null || String(comprovante.lojaCnpj).trim().length == 0 ? 'Não informado' : comprovante.lojaCnpj }}
               - IE: {{ comprovante.lojaIe == null || String(comprovante.lojaIe).trim().length == 0 ? 'Não informado' : comprovante.lojaIe }}
            </div>
            <div class="col-12 px-1">
               {{ comprovante.lojaEndereco == null || String(comprovante.lojaEndereco).trim().length == 0 ? 'Não informado' : comprovante.lojaEndereco }}
               <br/> Telefone: {{ comprovante.lojaTelefone == null || String(comprovante.lojaTelefone).trim().length == 0 ? 'Não informado' : comprovante.lojaTelefone }}
            </div>
         </div>

         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-12 px-1 text-center">Detalhes do comprovante de vasilhame</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-4 px-1 mb-1">Operador(a):</div>
            <div class="col-8 px-1 mb-1 text-end text-truncate">{{ comprovante.usuario }}</div>
            <div class="col-4 px-1 mb-1">Data:</div>
            <div class="col-8 px-1 mb-1 text-end text-truncate">{{ comprovante.data }}</div>
            <div class="col-4 px-1 mb-1">Código:</div>
            <div class="col-8 ps-1 pe-0 mb-1 text-end text-truncate letter-spacing">{{ comprovante.codigo }}</div>
         </div>
         
         <hr class="bg-transparent mb-2 mt-3" style="border: 1px dashed #000;">

         <div class="row m-0 align-items-center" style="font-size: 11.5px;">
            <div class="col-4 px-1 text-truncate">Código</div>
            <div class="col-6 px-0 text-truncate">Descrição</div>
            <div class="col-2 px-1 text-truncate text-end">Quant.</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center" style="font-size: 11.5px;" v-for="(p, index) in comprovante.itens" :key="index">
            <div class="col-4 px-1 text-truncate">{{ p.codigo }}</div>
            <div class="col-6 px-0 text-truncate">{{ p.nome }}</div>
            <div class="col-2 px-1 text-truncate text-end">{{ p.quantidade }}</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
      </div>
   </div>
</template>

<script>

export default {
	name: 'ComprovanteVasilhame',
	props: ['comprovante']
}

</script>